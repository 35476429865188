.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none none none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "selectCommunity selectCommunity"
    "propertySelector propertySelector"
    "searchVehicleTxt searchVehicleTxt"
    "plateField plateBtn"
    "vinField vinBtn";
  margin: auto;
  width: 80%;
  padding-top: 30px;
  padding-bottom: 70px;
}
.selectCommunity {
  grid-area: selectCommunity;
  text-align: center;
  font-size: larger;
  font-weight: bolder;
  margin-bottom: 30px;
}
.propertySelector {
  grid-area: propertySelector;
  text-align: center;
}
.searchVehicleTxt {
  grid-area: searchVehicleTxt;
  text-align: center;
  font-size: larger;
  font-weight: bolder;
  padding-top: 20px;
}
.plateField {
  grid-area: plateField;
  text-align: center;
  padding-top: 20px;
}
.plateBtn {
  grid-area: plateBtn;
  text-align: center;
  padding-top: 25px;
}
.vinField {
  grid-area: vinField;
  padding-top: 5px;
  text-align: center;
}
.vinBtn {
  grid-area: vinBtn;
  text-align: center;
  padding-top: 10px;
}
