.logo {
  padding-top: 10%;
  text-align: center;
}

.pageTitle {
  text-align: center;
  font-size: larger;
  font-weight: bolder;
  padding: 30px;
}
