.Form_Container {
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;

  text-align: center;
}
  
.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr .1fr .1fr .1fr .1fr .1fr;
  gap: 0px 0px;
  grid-template-areas:
    "logo"
    "enforcementApp"
    "email"
    "pass"
    "btn"
    "links";
  margin: auto;
  max-width: 600px;
  text-align: center;
}
.logo {
  grid-area: logo;
  padding-top: 75px;
}
.enforcementApp {
  grid-area: enforcementApp;
  color: #0EC47E;
  font-weight: bold;
  font-size: large;
}
.email { 
  grid-area: email;
  padding-top: 40px;
}
.pass {
  grid-area: pass;
  margin-top: 5px;
}
.btn { grid-area: btn; }
.links { grid-area: links; }