.container {
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: .75fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr; 
  gap: 0px 10px; 
  grid-template-areas: row;
  grid-template-areas: 
    "button button"
    "community community"
    "query query"
    "plateTxt plateField"
    "vinTxt vinField"
    "timeTxt timeField"
    "results results"; 
  margin: auto;
  margin-top: 10px;
  width: 80%;
}
.backButton {
  text-align: center;
  grid-area: button;
}
.community {
  grid-area: community;
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  padding-top: 20px;
}
.query {
  grid-area: query;
  text-align: center;
  font-size: larger;
  font-weight: bolder;
  padding-top: 20px;
}
.plateTxt {
  grid-area: plateTxt;
  text-align: right;
  padding-top: 20px;
  font-size: x-large;
}
.plateField {
  grid-area: plateField;
  text-align: left;
  font-weight: bolder;
  padding-top: 20px;
  font-size: x-large;
}
.vinTxt {
  grid-area: vinTxt;
  text-align: right;
  padding-top: 20px;
  font-size: x-large;
}
.vinField {
  grid-area: vinField;
  text-align: left;
  font-weight: bolder;
  padding-top: 20px;
  font-size: x-large;
}
.timeTxt {
  grid-area: timeTxt;
  text-align: right;
  font-weight: bolder;
  padding-top: 20px;
}
.timeField {
  grid-area: timeField;
  padding-top: 20px;
  text-align: left;
}
.results {
  grid-area: results;
  text-align: center;
}
.resultsContainer {  
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .1fr .1fr .1fr .1fr .1fr .1fr .1fr .1fr;
  grid-auto-columns: 1fr;
  gap: px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "active"
    "vehicle"
    "plate"
    "vin"
    "permit"
    "startDate"
    "endDate"
    "parking";
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #666;
  border-radius: 10px;
}
.active {
  grid-area: active;
  text-align: center;
  font-weight: bolder;
  background-color: #00ff00;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.noPermitFound {
  grid-area: active;
  text-align: center;
  font-weight: bolder;
  background-color: red;
  border: 1px solid #666;
  padding-top: 10px;
  padding-bottom: 2px;
  height: 35px;
  border-radius: 10px;
}
.expiredOrCancelled {
  grid-area: active;
  text-align: center;
  font-weight: bolder;
  background-color: red;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.vehicle {
  grid-area: vehicle;
  text-align: center;
  font-weight: bolder;
  font-size: small;
  padding-top: 10px;
  background-color: #e6e6e6;
}
.plate {
  grid-area: plate;
  text-align: center;
  font-size: small;
  background-color: #e6e6e6;
}
.vin {
  grid-area: vin;
  text-align: center;
  font-size: small;
  background-color: #e6e6e6;
}
.permit {
  grid-area: permit;
  text-align: center;
  padding-bottom: 10px;
  font-size: small;
  background-color: #e6e6e6;
}
.startDate {
  grid-area: startDate;
  text-align: center;
  font-size: small;
  background-color: #e6e6e6;
}
.endDate {
  grid-area: endDate;
  text-align: center;
  padding-bottom: 10px;
  font-size: small;
  background-color: #e6e6e6;
}
.parking {
  grid-area: parking;
  text-align: center;
  font-size: small;
  background-color: #FFF44F;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}
.noParking {
  grid-area: parking;
  text-align: center;
  font-size: small;
  background-color: #e6e6e6;
  padding-bottom: 10px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}
